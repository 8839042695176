import * as THREE from "three";
import { ShaderMaterial, TextureLoader } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";
import Stats from "three/examples/jsm/libs/stats.module";
import shadersLoad from "./shaders";

// let cubeFaces = [
//   [
//     new THREE.Vector3(1, 1, 1),
//     new THREE.Vector3(1, 1, -1),
//     new THREE.Vector3(1, -1, -1),
//     new THREE.Vector3(1, -1, 1),
//     new THREE.Vector3(0, 0, 0),
//   ],
//   [
//     new THREE.Vector3(-1, 1, -1),
//     new THREE.Vector3(-1, 1, 1),
//     new THREE.Vector3(-1, -1, 1),
//     new THREE.Vector3(-1, -1, -1),
//     new THREE.Vector3(0, 0, 0),
//   ],
//   [
//     new THREE.Vector3(-1, 1, -1),
//     new THREE.Vector3(1, 1, -1),
//     new THREE.Vector3(1, 1, 1),
//     new THREE.Vector3(-1, 1, 1),
//     new THREE.Vector3(0, 0, 0),
//   ],
//   [
//     new THREE.Vector3(-1, -1, 1),
//     new THREE.Vector3(1, -1, 1),
//     new THREE.Vector3(1, -1, -1),
//     new THREE.Vector3(-1, -1, -1),
//     new THREE.Vector3(0, 0, 0),
//   ],
// ];

let globalState = {
  resolution: 1024,
  performanceMode: true,
  // upperMarchBound: 135,
  // maxAlphaPerDist: 2859.9285, //3.3,
  rayScale: 2.79, //11.0, //0.0004,//4.79,

  lightPosX: 18.04,
  lightPosY: -5.08,
  lightPosZ: 0,

  backgroundColor: 0xb2b2b2,
  hideBox: false,

  noLight: false,
  noColor: false,
  noDensity: false,
  showSteps: false,

  N: 1,
  speed: 2,
  runTime: true,
  t: 0,
  animation: false,
  animationStart: 0.0,
  animationEnd: 0.0,
};

let smokeState = {
  hideOuterTransforms: true,
  hideInnerTransforms: true,
  showWireFrame: false,
  performanceMode: true,
  gasMeshGeometry: "box",
  upperMarchBound: 85,
  maxAlphaPerDist: 2.3,
  blendThreshold: 0.0,
  rayScale: 2.79,
  smokeScaleX: 1,
  smokeScaleY: 1,
  smokeScaleZ: 1,

  form: "spherical",

  noLight: false,
  noColor: false,
  noDensity: false,
  showSteps: false,

  cropFire: false,
  surrounding: 1.2,

  colorM: 0.7,
  colorO: 0.8,
  a: 0x7f7f7f, //0x7F7F7F, 0xff4000
  b: 0x7f7f7f, //0x7F7F7F, 0xff006f
  c: 0xffffff, //0xFFFFFF, 0xb10abd
  d: 0x001933, //0x001933, 0x4b2a2c

  power: 10, //1.52,
  radius: 0.4,
  innerWidth: 0.189,
  outerWidth: 1.828,

  firstM: 0.6,
  secondM: 0.3,
  thirdM: 0.3,

  mix1: 0.7,
  mix2: 0.7,

  xStretch: 1.0,
  yStretch: 1.0,
  freq: 0.3,

  meshOpacity: 0.0,

  offset: 7.0,
  surrounding: 1.0,

  innerOffsetX: 0.0,
  innerOffsetY: 0.0,
  innerOffsetZ: 0.0,

  downPower: 4.0,
  upPower: 12.0,
  downStep: 0.6,
  upStep: 1.0,

  power: 10, //1.52,
  radius: 0.4,

  verticalFadeDown: 0.0,
  verticalFadeUp: 0.63,

  baseDensity: 0.0,
  densityMultiplier: 1.3,
};

// base noise to remember
// firstM: 0.6,
// secondM: 0.3,
// thirdM: 0.3,

// mix1: 0.7,
// mix2: 0.7

let shaders = shadersLoad();
let gui = new GUI();

let fGlobals = gui.addFolder("Globals");
fGlobals.add(globalState, "hideBox", false, true);
fGlobals.add(globalState, "noLight", false, true);
fGlobals.add(globalState, "noColor", false, true);
fGlobals.add(globalState, "noDensity", false, true);
fGlobals.add(globalState, "showSteps", false, true);
fGlobals.add(globalState, "N", 0, 5, 1);
fGlobals.addColor(globalState, "backgroundColor");
fGlobals.close();

let fLight = gui.addFolder("Lights");
fLight.add(globalState, "lightPosX", -100, 100);
fLight.add(globalState, "lightPosY", -100, 100);
fLight.add(globalState, "lightPosZ", -100, 100);
fLight.close();

let fTime = gui.addFolder("TimeControl");
fTime.add(globalState, "animation", false, true);
fTime.add(globalState, "animationStart", 0, 1000);
fTime.add(globalState, "animationEnd", 0, 1000);
fTime.add(globalState, "runTime", true, false);
fTime.add(globalState, "t", 0, 1000);
fTime.add(globalState, "speed", 0.1, 100);
fTime.close();

let cubeFaces = [
  [
    new THREE.Vector3(-1, 1, -1),
    new THREE.Vector3(1, 1, -1),
    new THREE.Vector3(1, 1, 1),
    new THREE.Vector3(-1, 1, 1),
    new THREE.Vector3(0, 0, 0),
  ],
  [
    new THREE.Vector3(-1, -1, 1),
    new THREE.Vector3(1, -1, 1),
    new THREE.Vector3(1, -1, -1),
    new THREE.Vector3(-1, -1, -1),
    new THREE.Vector3(0, 0, 0),
  ],
];

class ShaderQuad {
  constructor(shader, width, height) {
    this.material = new ShaderMaterial(shader);

    this.quad = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), this.material);
    this.quadScene = new THREE.Scene();
    this.quadCamera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -1, 1);

    this.quad.position.copy(new THREE.Vector3(0, 0, 0));
    this.quadScene.add(this.quad);
    this.quadScene.add(this.quadCamera);
    this.quadFBO = new THREE.WebGLRenderTarget(width, height);
  }

  render(renderer, renderToScreen) {
    if (renderToScreen) renderer.setRenderTarget(null);
    else renderer.setRenderTarget(this.quadFBO);

    renderer.render(this.quadScene, this.quadCamera);
    renderer.setRenderTarget(null);
  }
}

let geoSphere = new THREE.SphereGeometry(4, 32);
//let geoBox = new THREE.BoxGeometry(10, 12, 10);
let geoBox = new THREE.BoxGeometry(1, 1, 1);

let N = 0;

class SmokeObject {
  constructor(scene, camera, stateIn) {
    this.state = { ...stateIn };
    let { state } = this;
    this.index = N;
    N++;

    this.qs = cubeFaces.map((f) => {
      return new ShaderQuad(
        {
          defines: {
            PERFORMANCE_MODE: state.performanceMode ? 1 : 0,
            FORM: ["none", "conical", "spherical"].indexOf(state.form),
          },
          uniforms: {
            lightSmokePos: { type: "v3", value: lightSmokePos },
            tDiffuse: { type: "t", value: null },
            iTime: { type: "f", value: 0 },
            unCorners: {
              value: f,
            },
            smokeScale: { type: "v3", value: new THREE.Vector3(state.smokeScaleX, state.smokeScaleY, state.smokeScaleZ) },
            innerOffset: { type: "v3", value: new THREE.Vector3(0, 0, 0) },
            rayScale: { type: "f", value: state.rayScale },
            maxAlphaPerDist: { type: "f", value: state.maxAlphaPerDist },
            upperMarchBound: { type: "f", value: state.upperMarchBound },

            cropFire: { type: "f", value: state.cropFire ? 1.0 : 0.0 },
            surrounding: { type: "f", value: state.surrounding },
            k: { type: "f", value: state.k },
            offset: { type: "f", value: 0 },
            innerWidth: { type: "f", value: state.innerWidth },
            outerWidth: { type: "f", value: state.outerWidth },
            radius: { type: "f", value: state.radius },
            power: { type: "f", value: state.power },
            verticalFadeUp: { type: "f", value: 0 },
            verticalFadeDown: { type: "f", value: 0 },

            downPower: { type: "f", value: 0 },
            upPower: { type: "f", value: 0 },
            downStep: { type: "f", value: 0 },
            upStep: { type: "f", value: 0 },

            firstM: { type: "f", value: state.firstM },
            secondM: { type: "f", value: state.secondM },
            thirdM: { type: "f", value: state.thirdM },
            mix1: { type: "f", value: state.mix1 },
            mix2: { type: "f", value: state.mix2 },

            xStretch: { type: "f", value: state.xStretch },
            yStretch: { type: "f", value: state.yStretch },
            freq: { type: "f", value: state.freq },

            innerInverseMatrixWorld: { type: "m4", value: new THREE.Matrix4() },
            innerMatrixWorld: { type: "m4", value: new THREE.Matrix4() },
          },
          vertexShader: shaders.quad_v,
          fragmentShader: shaders.light_f,
        },
        1024,
        1024
      );
    });

    this.mesh = new THREE.Mesh(
      geoBox,
      new THREE.ShaderMaterial({
        defines: {
          PERFORMANCE_MODE: globalState.performanceMode ? 1 : 0,
          FORM: ["none", "conical", "spherical"].indexOf(state.form),
        },
        uniforms: {
          sceneTex: { type: "t", value: null },
          cameraPosition: { type: "v3", value: new THREE.Vector3(0, 0, 25) },
          light1: { type: "t", value: null },
          light2: { type: "t", value: null },
          tDiffuse: { type: "t", value: null },
          densityTex: { type: "t", value: null },
          iResolution: { type: "vec2", value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
          iTime: { type: "f", value: 0 },
          rayScale: { type: "f", value: 0 },
          blendThreshold: { type: "f", value: 0 },

          maxAlphaPerDist: { type: "f", value: 0 },
          upperMarchBound: { type: "f", value: 0 },
          noLight: { type: "f", value: 0 },
          noColor: { type: "f", value: 0 },
          noDensity: { type: "f", value: 0 },
          showSteps: { type: "f", value: 0 },
          verticalFadeUp: { type: "f", value: 0 },
          verticalFadeDown: { type: "f", value: 0 },

          downPower: { type: "f", value: 0 },
          upPower: { type: "f", value: 0 },
          downStep: { type: "f", value: 0 },
          upStep: { type: "f", value: 0 },

          firstM: { type: "f", value: 0 },
          secondM: { type: "f", value: 0 },
          thirdM: { type: "f", value: 0 },
          mix1: { type: "f", value: 0 },
          mix2: { type: "f", value: 0 },

          xStretch: { type: "f", value: 0 },
          yStretch: { type: "f", value: 0 },
          freq: { type: "f", value: 0 },
          offset: { type: "f", value: 0 },
          cropFire: { type: "f", value: 0 },
          surrounding: { type: "f", value: 0 },
          radius: { type: "f", value: 0 },
          power: { type: "f", value: 0 },
          innerWidth: { type: "f", value: 0 },
          outerWidth: { type: "f", value: 0 },

          colorM: { type: "f", value: 0 },
          colorO: { type: "f", value: 0 },

          a: { type: "v3", value: new THREE.Color(0x0) },
          b: { type: "v3", value: new THREE.Color(0x0) },
          c: { type: "v3", value: new THREE.Color(0x0) },
          d: { type: "v3", value: new THREE.Color(0x0) },

          baseDensity: { type: "f", value: 0 },
          densityMultiplier: { type: "f", value: 0 },

          innerInverseMatrixWorld: { type: "m4", value: new THREE.Matrix4() },
          innerMatrixWorld: { type: "m4", value: new THREE.Matrix4() },

          smokeScale: { type: "v3", value: new THREE.Vector3(0, 0, 0) },
          innerOffset: { type: "v3", value: new THREE.Vector3(0, 0, 0) },
        },
        vertexShader: shaders.mesh_v,
        fragmentShader: shaders.final_f,
      })
    );

    this.tr = new TransformControls(camera, renderer.domElement);
    this.innerObject = new THREE.Object3D();
    this.tr2 = new TransformControls(camera, renderer.domElement);

    let { mesh, tr, tr2, innerObject } = this;

    mesh.material.transparent = true;
    mesh.material.side = THREE.FrontSide; //THREE.BackSide;

    mesh.scale.copy(new THREE.Vector3(10, 12, 10));
    mesh.updateMatrixWorld();

    tr.attach(mesh);

    innerObject.scale.copy(new THREE.Vector3(5.0, 5.0, 5.0));

    tr2.attach(innerObject);

    tr.addEventListener("dragging-changed", function (event) {
      controls.enabled = !event.value;
    });

    tr2.addEventListener("dragging-changed", function (event) {
      controls.enabled = !event.value;
    });

    this.wireMesh = new THREE.Mesh(geoBox, new THREE.MeshBasicMaterial({ wireframe: true }));
    this.mesh.add(this.wireMesh);

    scene.add(mesh);
    scene.add(innerObject);
    scene.add(tr);
    scene.add(tr2);
    this.update();
  }

  buildGUI() {
    let state = this.state;

    this.fSmoke = gui.addFolder("Smoke" + this.index);

    let { fSmoke } = this;

    let f0 = fSmoke.addFolder("Rendering");
    f0.add(state, "hideOuterTransforms", true, false);
    f0.add(state, "hideInnerTransforms", true, false);
    //f0.add(state, "meshOpacity", 0.0, 1.0);
    f0.add(state, "showWireFrame", false, true);
    f0.add(state, "gasMeshGeometry", ["box", "sphere"]).onChange((v) => {
      this.mesh.geometry = v == "box" ? geoBox : geoSphere;
      this.wireMesh.geometry = v == "box" ? geoBox : geoSphere;

      this.mesh.scale.copy(v == "box" ? new THREE.Vector3(10, 12, 10) : new THREE.Vector3(1, 1, 1));
      this.mesh.updateMatrixWorld();
    });
    f0.add(state, "upperMarchBound", 20, 200, 1);
    f0.add(state, "rayScale", 0.1, 50);
    //f0.add(state, "blendThreshold", 0.0, 1.0);
    f0.add(state, "maxAlphaPerDist", 0.1, 40);
    f0.add(state, "verticalFadeUp", 0.0, 10.0);
    f0.add(state, "verticalFadeDown", 0.0, 10.0);
    f0.add(state, "form", ["none", "conical", "spherical"]).onChange((v) => {
      this.qs.forEach((q) => {
        q.material.defines["FORM"] = ["none", "conical", "spherical"].indexOf(v);
        q.material.needsUpdate = true;
      });
    });

    f0.add(state, "performanceMode", true, false).onChange((v) => {
      this.mesh.material.defines.PERFORMANCE_MODE = v ? 1 : 0;
      this.mesh.material.needsUpdate = true;
      this.qs.forEach((q) => {
        q.material.defines.PERFORMANCE_MODE = v ? 1 : 0;
        q.material.needsUpdate = true;
      });
    });
    f0.close();

    let fRandom = fSmoke.addFolder("Random");
    fRandom.add(state, "firstM", 0.0, 3);
    fRandom.add(state, "secondM", 0.0, 3);
    fRandom.add(state, "thirdM", 0.0, 3);
    fRandom.add(state, "mix1", 0.0, 1);
    fRandom.add(state, "mix2", 0.0, 1);
    fRandom.add(state, "xStretch", 0.0, 5.0);
    fRandom.add(state, "yStretch", 0.0, 5.0);
    fRandom.add(state, "freq", 0.0, 5.0);
    fRandom.close();

    let fInnerOffsets = fSmoke.addFolder("InnerOffsetsAndScales");
    fInnerOffsets.add(state, "smokeScaleX", 0.1, 50);
    fInnerOffsets.add(state, "smokeScaleY", 0.1, 50);
    fInnerOffsets.add(state, "smokeScaleZ", 0.1, 50);
    // fInnerOffsets.add(state, "cropFire", false, true);
    // fInnerOffsets.add(state, "surrounding", 0.5, 4);
    fInnerOffsets.add(state, "innerOffsetX", -256, 256);
    fInnerOffsets.add(state, "innerOffsetY", -256, 256);
    fInnerOffsets.add(state, "innerOffsetZ", -256, 256);
    fInnerOffsets.close();

    let fColors = fSmoke.addFolder("Color");
    fColors.add(state, "colorM", 0.0, 2.0);
    fColors.add(state, "colorO", 0.0, 2.0);
    fColors.addColor(state, "a");
    fColors.addColor(state, "b");
    fColors.addColor(state, "c");
    // fColors.addColor(state, "d");
    // fColors.add(state, "noLight", false, true);
    // fColors.add(state, "noColor", false, true);
    // fColors.add(state, "noDensity", false, true);
    // fColors.add(state, "showSteps", false, true);
    fColors.close();

    let fSphere = fSmoke.addFolder("SphericalParameters");
    fSphere.add(state, "radius", 0, 1);
    fSphere.add(state, "power", 0, 10);
    fSphere.close();

    let fCyl = fSmoke.addFolder("ConicalParameters");
    fCyl.add(state, "innerWidth", 0, 1);
    fCyl.add(state, "outerWidth", 0, 2);
    fCyl.add(state, "power", 0, 10);
    fCyl.close();

    this.fSmoke.close();
    return this;
  }

  setPosition(position) {
    this.innerObject.position.copy(position);
    this.mesh.position.copy(position);
    return this;
  }

  update(t) {
    let { qs, mesh, tr, tr2, innerObject, state } = this;

    tr.enabled = state.hideOuterTransforms ? false : true;
    tr.visible = state.hideOuterTransforms ? false : true;

    tr2.enabled = state.hideInnerTransforms ? false : true;
    tr2.visible = state.hideInnerTransforms ? false : true;
    //tr.update();
    //mesh.lookAt(camera.position);

    innerObject.updateMatrixWorld();

    qs.forEach((q) => {
      q.material.uniforms.upperMarchBound.value = state.upperMarchBound;
      q.material.uniforms.lightSmokePos.value.copy(new THREE.Vector3(globalState.lightPosX, globalState.lightPosY, globalState.lightPosZ));
      q.material.uniforms.rayScale.value = state.rayScale;
      q.material.uniforms.smokeScale.value = new THREE.Vector3(state.smokeScaleX, state.smokeScaleY, state.smokeScaleZ);
      q.material.uniforms.innerOffset.value = new THREE.Vector3(this.state.innerOffsetX, this.state.innerOffsetY, this.state.innerOffsetZ);
      q.material.uniforms.maxAlphaPerDist.value = state.maxAlphaPerDist;
      q.material.uniforms.cropFire.value = state.cropFire ? 1.0 : 0.0;
      q.material.uniforms.surrounding.value = state.surrounding;
      q.material.uniforms.k.value = state.k;
      q.material.uniforms.offset.value = this.state.offset;
      q.material.uniforms.innerWidth.value = state.innerWidth;
      q.material.uniforms.outerWidth.value = state.outerWidth;
      q.material.uniforms.radius.value = state.radius;
      q.material.uniforms.power.value = state.power;
      q.material.uniforms.verticalFadeDown.value = state.verticalFadeDown;
      q.material.uniforms.verticalFadeUp.value = state.verticalFadeUp;

      q.material.uniforms.firstM.value = state.firstM;
      q.material.uniforms.secondM.value = state.secondM;
      q.material.uniforms.thirdM.value = state.thirdM;
      q.material.uniforms.mix1.value = state.mix1;
      q.material.uniforms.mix2.value = state.mix2;

      q.material.uniforms.downPower.value = this.state.downPower;
      q.material.uniforms.upPower.value = this.state.upPower;
      q.material.uniforms.downStep.value = this.state.downStep;
      q.material.uniforms.upStep.value = this.state.upStep;

      q.material.uniforms.xStretch.value = state.xStretch;
      q.material.uniforms.yStretch.value = state.yStretch;
      q.material.uniforms.freq.value = state.freq;

      q.material.uniforms.innerMatrixWorld.value.copy(innerObject.matrixWorld);
      q.material.uniforms.innerInverseMatrixWorld.value.copy(innerObject.matrixWorld);
      q.material.uniforms.innerInverseMatrixWorld.value.invert();

      q.material.uniforms.iTime.value = t;

      q.render(renderer);
    });

    // mesh.material.uniforms.blendThreshold.value = state.blendThreshold;
    mesh.material.uniforms.light1.value = qs[0].quadFBO.texture;
    mesh.material.uniforms.light2.value = qs[1].quadFBO.texture;
    // mesh.material.uniforms.upperMarchBound.value = state.upperMarchBound;
    // mesh.material.uniforms.iTime.value = (state.speed * t) / 1000;
    // mesh.material.uniforms.cameraPosition.value.copy(camera.position);
    // mesh.material.uniforms.rayScale.value = state.rayScale;
    // mesh.material.uniforms.smokeScale.value = new THREE.Vector3(state.smokeScaleX, state.smokeScaleY, state.smokeScaleZ);
    // mesh.material.uniforms.maxAlphaPerDist.value = state.maxAlphaPerDist;
    // mesh.material.uniforms.noLight.value = state.noLight ? 1.0 : 0.0;
    // mesh.material.uniforms.noColor.value = state.noColor ? 1.0 : 0.0;
    // mesh.material.uniforms.noDensity.value = state.noDensity ? 1.0 : 0.0;
    // mesh.material.uniforms.showSteps.value = state.showSteps ? 1.0 : 0.0;
    // mesh.material.uniforms.verticalFade.value = state.verticalFade;

    // mesh.material.uniforms.colorM.value = state.colorM;
    // mesh.material.uniforms.colorO.value = state.colorO;
    // mesh.material.uniforms.a.value = new THREE.Color(state.a);
    // mesh.material.uniforms.b.value = new THREE.Color(state.b);
    // mesh.material.uniforms.c.value = new THREE.Color(state.c);
    // mesh.material.uniforms.d.value = new THREE.Color(state.d);
    // mesh.material.uniforms.meshOpacity.value = state.meshOpacity;

    // mesh.material.uniforms.innerMatrixWorld.value.copy(innerObject.matrixWorld);
    // mesh.material.uniforms.innerInverseMatrixWorld.value.copy(innerObject.matrixWorld);
    // mesh.material.uniforms.innerInverseMatrixWorld.value.invert();

    let mat = mesh.material;

    if (mat.defines["FORM"] != ["none", "conical", "spherical"].indexOf(globalState.form)) {
      mat.defines["FORM"] = ["none", "conical", "spherical"].indexOf(globalState.form);
      mat.needsUpdate = true;
    }

    mat.uniforms.cameraPosition.value.copy(camera.position);

    mat.uniforms.iTime.value = t; // + this.offset;

    mat.uniforms.upperMarchBound.value = this.state.upperMarchBound;
    mat.uniforms.rayScale.value = this.state.rayScale;
    mat.uniforms.maxAlphaPerDist.value = this.state.maxAlphaPerDist;

    mat.uniforms.noLight.value = globalState.noLight ? 1.0 : 0.0;
    mat.uniforms.noColor.value = globalState.noColor ? 1.0 : 0.0;
    mat.uniforms.noDensity.value = globalState.noDensity ? 1.0 : 0.0;
    mat.uniforms.showSteps.value = globalState.showSteps ? 1.0 : 0.0;

    mat.uniforms.innerWidth.value = this.state.innerWidth;
    mat.uniforms.outerWidth.value = this.state.outerWidth;
    mat.uniforms.radius.value = this.state.radius;
    mat.uniforms.power.value = this.state.power;

    mat.uniforms.verticalFadeUp.value = this.state.verticalFadeUp;
    mat.uniforms.verticalFadeDown.value = this.state.verticalFadeDown;

    mat.uniforms.downPower.value = this.state.downPower;
    mat.uniforms.upPower.value = this.state.upPower;
    mat.uniforms.downStep.value = this.state.downStep;
    mat.uniforms.upStep.value = this.state.upStep;

    mat.uniforms.firstM.value = this.state.firstM;
    mat.uniforms.secondM.value = this.state.secondM;
    mat.uniforms.thirdM.value = this.state.thirdM;
    mat.uniforms.mix1.value = this.state.mix1;
    mat.uniforms.mix2.value = this.state.mix2;
    mat.uniforms.xStretch.value = this.state.xStretch;
    mat.uniforms.yStretch.value = this.state.yStretch;
    mat.uniforms.freq.value = this.state.freq;

    mat.uniforms.offset.value = this.state.offset;
    mat.uniforms.surrounding.value = this.state.surrounding;
    mat.uniforms.cropFire.value = this.state.cropFire ? 1.0 : 0.0;
    //this.state.innerOffsetY = globalState.r * 100;
    mat.uniforms.smokeScale.value = new THREE.Vector3(this.state.smokeScaleX, this.state.smokeScaleY, this.state.smokeScaleZ);
    mat.uniforms.innerOffset.value = new THREE.Vector3(this.state.innerOffsetX, this.state.innerOffsetY, this.state.innerOffsetZ);

    mat.uniforms.colorM.value = this.state.colorM;
    mat.uniforms.colorO.value = this.state.colorO;

    mat.uniforms.baseDensity.value = this.state.baseDensity;
    mat.uniforms.densityMultiplier.value = this.state.densityMultiplier; //Math.pow(4 *state.baseDensity, 2.0 ) * globalState.densityMultiplier;

    mat.uniforms.a.value = new THREE.Color(this.state.a);
    mat.uniforms.b.value = new THREE.Color(this.state.b);
    mat.uniforms.c.value = new THREE.Color(this.state.c);
    mat.uniforms.d.value = new THREE.Color(this.state.d);

    mat.uniforms.innerMatrixWorld.value.copy(innerObject.matrixWorld);
    mat.uniforms.innerInverseMatrixWorld.value.copy(innerObject.matrixWorld);
    mat.uniforms.innerInverseMatrixWorld.value.invert();
  }

  //render() {}
}

let renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

let scene = new THREE.Scene();

scene.background = new THREE.Color(0xb2b2b2);

let object = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), new THREE.MeshBasicMaterial({ color: new THREE.Color(0x0) }));

let camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.copy(new THREE.Vector3(0, 0, 25));
scene.add(camera);
scene.add(object);

let controls = new OrbitControls(camera, renderer.domElement);
let sceneFBO = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight);

let lightSmokePos = new THREE.Vector3(globalState.lightPosX, globalState.lightPosY, globalState.lightPosZ);

let smokes = [
  new SmokeObject(scene, camera, smokeState).setPosition(new THREE.Vector3(0, 0, 0)).buildGUI(),
  new SmokeObject(scene, camera, smokeState).setPosition(new THREE.Vector3(20, 0, 0)).buildGUI(),
  new SmokeObject(scene, camera, smokeState).setPosition(new THREE.Vector3(-20, 0, 0)).buildGUI(),
  new SmokeObject(scene, camera, smokeState).setPosition(new THREE.Vector3(0, 0, 20)).buildGUI(),
  new SmokeObject(scene, camera, smokeState).setPosition(new THREE.Vector3(0, 0, -20)).buildGUI(),
];

window.addEventListener("keydown", function (event) {
  switch (event.keyCode) {
    case 87: // W
      smokes.forEach((smoke) => {
        smoke.tr.setMode("translate");
        smoke.tr2.setMode("translate");
      });
      break;

    case 69: // E
      smokes.forEach((smoke) => {
        smoke.tr.setMode("rotate");
        smoke.tr2.setMode("rotate");
      });
      break;

    case 82: // R
      smokes.forEach((smoke) => {
        smoke.tr.setMode("scale");
        smoke.tr2.setMode("scale");
      });
      break;
  }
});

let start = Date.now();

let stats = Stats();
document.body.appendChild(stats.domElement);

function render() {
  stats.begin();
  //let t = Date.now() - start;
  if (globalState.animation && globalState.animationEnd > globalState.animationStart) {
    globalState.t = globalState.runTime
      ? globalState.animationStart +
        (Math.max(globalState.t + (globalState.speed * 1.0) / 60 - globalState.animationStart, 0.0) %
          (globalState.animationEnd - globalState.animationStart))
      : globalState.t;
  } else {
    globalState.t = globalState.runTime ? globalState.t + (globalState.speed * 1.0) / 60 : globalState.t;
    globalState.t = globalState.t % 1000;
  }

  let t = globalState.t;
  requestAnimationFrame(render);
  controls.update();
  smokes.forEach((smoke, i) => {
    if (i < globalState.N) smoke.update(t);
    smoke.mesh.visible = i < globalState.N;
    smoke.wireMesh.visible = smoke.state.showWireFrame;
  });

  //renderer.setRenderTarget(sceneFBO);
  scene.background.set(globalState.backgroundColor);
  object.visible = !globalState.hideBox;
  renderer.render(scene, camera);
  // renderer.setRenderTarget(null);

  // mesh.material.uniforms.sceneTex.value = sceneFBO.texture;

  //sp.render(renderer, true);
  //renderer.render(scene, camera);
  stats.end();
}

new TextureLoader().load("2.png", (tex) => {
  tex.minFilter = THREE.LinearFilter;
  tex.wrapS = THREE.RepeatWrapping;
  tex.wrapT = THREE.RepeatWrapping;
  smokes.forEach((smoke) => {
    smoke.qs.forEach((q) => {
      q.material.uniforms.tDiffuse.value = tex;

      q.quadFBO.texture.wrapS = THREE.RepeatWrapping;
      q.quadFBO.texture.wrapT = THREE.RepeatWrapping;
      q.quadFBO.texture.magFilter = THREE.NearestFilter;
      q.quadFBO.texture.minFilter = THREE.NearestFilter;
    });
  });

  render();
});
