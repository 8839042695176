import final_f from "./shaders/final.glslf";
import light_f from "./shaders/light.glslf";
import quad_v from "./shaders/quad.glslv";
import mesh_v from "./shaders/mesh.glslv";

export default () => {
  return {
    final_f: final_f,
    light_f: light_f,
    quad_v: quad_v,
    mesh_v: mesh_v
  };
};
